<template>
  <div>
    <div class="">
      <div class="wrapper">
        <v-form class="form" @submit.stop.prevent="submit">
          <div class="main " style="background:#f1f1f1;">
            <div class="section">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="card-title">Product Builder</h3>

                    <div v-if="currentUser.expert"></div>

                    <v-card v-else dark color="teal">
                      <div class="container">
                        <div class="row">
                          <div class="col-12">
                            <p>
                              This is where you enter in the product info to
                              create your products. Once you have the product
                              created you can create variants.

                              <br />
                              <br />
                              Are you a master at building products? Turn on
                              expert mode now for our quick and extended product
                              view.
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
                <div v-if="isLoading" class="d-flex justify-center mb-6">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                    class="popup-visible"
                  ></v-progress-circular>
                </div>
                <div>
                  <div class="wrapper">
                    <div>
                      <div>
                        <div>
                          <v-btn
                            elevation="9"
                            rounded
                            small
                            color="orange  darken-2"
                            href="/pim/list"
                          >
                            <v-icon left>
                              mdi-arrow-left
                            </v-icon>
                            Back
                          </v-btn>

                          <div class="row mt-4">
                            <div class="col-md-8">
                              <label class="labels" for="#firstName">
                                <strong> Product Name </strong></label
                              >
                              <v-text-field
                                id="name"
                                v-model="name"
                                placeholder="Product Name"
                                background-color="white"
                                required
                                filled
                              ></v-text-field>

                              <label class="labels" for="#firstName"
                                ><strong> Description </strong>
                              </label>
                              <ckeditor
                                v-if="long_description != null"
                                v-model="long_description"
                                :editor="editor"
                                :config="editorConfig"
                              ></ckeditor>

                              <div class="row mt-4">
                                <div class="col-md-3 align-self-center">
                                  <label class="labels" for="#email"
                                    >Use Tags to sort and organize your
                                    Products</label
                                  >
                                </div>
                                <div class="col-md-9 align-self-center">
                                  <v-combobox
                                    v-model="chips"
                                    :items="tagNames"
                                    chips
                                    clearable
                                    :search-input.sync="chipsearch"
                                    label="Enter Tags followed by Enter key. (TAGS are a OR Logic)"
                                    multiple
                                    hide-selected
                                    solo
                                    @change="chipsearch = ''"
                                  >
                                    <template
                                      #selection="{
                                        attrs,
                                        item,
                                        select,
                                        parent,
                                      }"
                                    >
                                      <v-chip
                                        v-bind="attrs"
                                        :input-value="selected"
                                        close
                                        @click="select"
                                        @click:close="parent.selectItem(item)"
                                      >
                                        <strong>{{ item }}</strong
                                        >&nbsp;
                                      </v-chip>
                                    </template>
                                    <template #no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            No results matching "<strong>{{
                                              chipsearch
                                            }}</strong
                                            >". Press <kbd>enter</kbd> to create
                                            a new one
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-combobox>
                                </div>
                              </div>

                              <label class="labels mt-6" for="#firstName"
                                ><strong>Short Description </strong>
                              </label>
                              <div class="pb-4">
                                <ckeditor
                                  v-if="short_description != null"
                                  v-model="short_description"
                                  :editor="editor"
                                  :config="editorConfig"
                                ></ckeditor>
                              </div>

                              <v-expansion-panels
                                v-model="panel2"
                                multiple
                                class="mb-6"
                              >
                                <!-- <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    <v-toolbar-title>
                                      Variants / Sku's</v-toolbar-title
                                    >
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-toolbar flat>
                                      <v-spacer></v-spacer>
                                      <v-spacer></v-spacer>

                                      Create the Product and Save to create
                                      Items
                                    </v-toolbar>

                                    <b-table
                                      striped
                                      hover
                                      :items="items"
                                      :fields="fields"
                                    >
                                      <template #cell(name)="data">
                                        {{ data.item.Name }}
                                      </template>
                                      <template #cell(sku)="data">
                                        {{ data.item.sku_id }}
                                      </template>
                                      <template #cell(actions)="data">
                                        <v-btn
                                          icon
                                          class=""
                                          dark
                                          color="success"
                                          @click="
                                            $router.push(
                                              '/pim/item-edit/' + data.item.id
                                            )
                                          "
                                        >
                                          <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>

                                        <v-btn
                                          icon
                                          dark
                                          class=""
                                          color="success"
                                          @click="removeItemBtn(data.item.id)"
                                        >
                                          <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                    </b-table>
                                    <div class="row mt-8">
                                      <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalCount"
                                        :per-page="limit"
                                        style="margin: 0 auto;"
                                      ></b-pagination>
                                    </div>
                                  </v-expansion-panel-content>
                                </v-expansion-panel> -->

                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    <v-toolbar-title>
                                      Attributes</v-toolbar-title
                                    >
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-card-text>
                                      <div class="row">
                                        <div class="col-lg-6">
                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Secondary Name</label
                                            >
                                            <div class="col-7">
                                              <input
                                                v-model="second_name"
                                                class="form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Case Pack</label
                                            >
                                            <div class="col-7">
                                              <input
                                                v-model="case_pack"
                                                class="form-control"
                                                type="number"
                                              />
                                            </div>
                                          </div>

                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Brand</label
                                            >
                                            <div class="col-7">
                                              <input
                                                id="brand"
                                                v-model="brand"
                                                class="form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                          <!-- <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >URL Key</label
                                            >
                                            <div class="col-7">
                                              <input
                                                id="url"
                                                v-model="url_key"
                                                class="form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div> -->
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Weight LBS</label
                                            >
                                            <div class="col-7">
                                              <input
                                                id="weight"
                                                v-model="weight"
                                                class="form-control"
                                                type="number"
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Length</label
                                            >
                                            <div class="col-7">
                                              <input
                                                id="length"
                                                v-model="length_in"
                                                class="form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Width</label
                                            >
                                            <div class="col-7">
                                              <input
                                                id="width"
                                                v-model="width_in"
                                                class="form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <label class="col-5 col-form-label"
                                              >Height</label
                                            >
                                            <div class="col-7">
                                              <input
                                                id="height"
                                                v-model="height_in"
                                                class="form-control"
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-lg-12">
                                          <div class="form-group row">
                                            <label
                                              class="col-12 col-form-label"
                                            >
                                              Shipping Info</label
                                            >

                                            <div class="col-12">
                                              <ckeditor
                                                v-model="shipping_info"
                                                :editor="editor"
                                                :config="editorConfig"
                                              ></ckeditor>

                                              <pre class="mt-3 mb-0"></pre>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-lg-12">
                                          <div class="form-group row">
                                            <label
                                              class="col-12 col-form-label"
                                            >
                                              Marketing text</label
                                            >

                                            <div class="col-12">
                                              <ckeditor
                                                v-model="marketing_text"
                                                :editor="editor"
                                                :config="editorConfig"
                                              ></ckeditor>

                                              <pre class="mt-3 mb-0"></pre>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-lg-12">
                                          <div class="form-group row">
                                            <label
                                              class="col-12 col-form-label"
                                            >
                                              Ingredient text - Food Products
                                              Only</label
                                            >

                                            <div class="col-12">
                                              <ckeditor
                                                v-model="ingredients"
                                                :editor="editor"
                                                :config="editorConfig"
                                              ></ckeditor>

                                              <pre class="mt-3 mb-0"></pre>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </v-card-text>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                            <div class="col-md-4">
                              <div class="mb-10" style="float:right;">
                                <v-btn
                                  elevation="9"
                                  rounded
                                  medium
                                  color="success"
                                  type="submit"
                                  class="mr-2 align-items-right"
                                >
                                  <v-icon left>
                                    mdi-content-save
                                  </v-icon>
                                  Save
                                </v-btn>
                              </div>

                              <div class=" mt-4 mb-4">
                                <v-checkbox
                                  v-model="featured"
                                  label="Feature on Profile"
                                >
                                </v-checkbox>
                                <strong> Status </strong>
                                <b-form-select
                                  id="input-horizontal"
                                  v-model="status"
                                  :options="product_options"
                                ></b-form-select>
                              </div>

                              <div class=" ">
                                <label class="labels" for="#productid"
                                  ><strong> Product ID </strong></label
                                >
                              </div>

                              <v-text-field
                                id="part_id"
                                v-model="part_id"
                                filled
                                background-color="white"
                                placeholder="Product ID"
                                required
                              ></v-text-field>

                              <v-expansion-panels v-model="panel" multiple>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Product Image
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <section class="mt-2  mb-6">
                                      <v-img
                                        class="mb-8"
                                        :src="
                                          main_image_url != ''
                                            ? baseurl + main_image_url
                                            : '/media/product-placeholder.jpg'
                                        "
                                      >
                                        <v-btn
                                          icon
                                          class="float-right"
                                          style="background-color: transparent;"
                                          @click="imageDelete"
                                        >
                                          <v-icon style="color: black;"
                                            >mdi-close</v-icon
                                          >
                                        </v-btn>
                                      </v-img>

                                      <v-spacer class="mb-4"> </v-spacer>

                                      <b-form-file
                                        v-model="main_image"
                                        :state="Boolean(main_image)"
                                        :placeholder="
                                          main_image_name == ''
                                            ? 'Upload Image'
                                            : main_image_name
                                        "
                                        drop-placeholder="Drop file here..."
                                        accept="image/*"
                                        @change="imageChange"
                                      ></b-form-file>
                                    </section>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>

                                <!-- <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Product Gallery
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row class="mr-1">
                                      <v-col
                                        v-for="(img,
                                        index) in additional_images"
                                        :key="index"
                                        cols="6"
                                        sm="6"
                                      >
                                        <v-img
                                          :src="
                                            img.url != ''
                                              ? baseurl + img.url
                                              : 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg'
                                          "
                                        >
                                          <div class=" repeating-gradient">
                                            <v-btn
                                              icon
                                              small
                                              class="float-right"
                                              style="background-color: #fff .5;"
                                              @click="removeImg(img.id)"
                                            >
                                              <v-icon style="color: black;"
                                                >mdi-close</v-icon
                                              >
                                            </v-btn>
                                          </div>
                                        </v-img>
                                      </v-col>
                                      <v-col cols="6" sm="4">
                                        <image-input
                                          v-model="additional_image"
                                          :state="Boolean(additional_image)"
                                        >
                                          <div slot="activator">
                                            <v-img
                                              v-if="!additional_image"
                                              v-ripple
                                              src="/media/upload.png"
                                              class="grey lighten-3 mb-3"
                                            >
                                            </v-img>
                                            <v-img
                                              v-else
                                              v-ripple
                                              size="150px"
                                              class="mb-3"
                                            >
                                              <img
                                                :src="additional_image.imageURL"
                                                alt="avatar"
                                              />
                                            </v-img>
                                          </div>
                                        </image-input>
                                        <v-slide-x-transition>
                                          <div
                                            v-if="
                                              additional_image && saved == false
                                            "
                                          >
                                            <v-btn
                                              class="primary"
                                              @click="addimageUpload"
                                              >Save image</v-btn
                                            >
                                          </div>
                                        </v-slide-x-transition>
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel> -->

                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Video Links
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <div class="form-group row">
                                          <label class="col-12 col-form-label">
                                            <strong>YouTube </strong> <br />
                                            You must use the embed url to add a
                                            video to the product listing page,
                                            example: FC7a2uE-9_o
                                          </label>
                                          <div class="col-12">
                                            <input
                                              id="youtube"
                                              v-model="youtube"
                                              class="form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>

                                        <div class="form-group row">
                                          <label class="col-5 col-form-label"
                                            >Vimeo</label
                                          >
                                          <div class="col-12">
                                            <input
                                              id="vimeo"
                                              v-model="vimeo"
                                              class="form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <v-dialog v-model="addDialog" persistent max-width="600px">
                      <v-form
                        v-model="addDialogformvalid"
                        @submit.prevent="addItem"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="headline">Add New Item</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="addDialogform.name"
                                    label="Name"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="addDialogform.skuid"
                                    label="SKU #"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="addDialogClose"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              type="submit"
                              :disabled="!addDialogformvalid"
                              color="blue darken-1"
                              text
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-dialog>
                  </div>
                </div>

                <div class="row"></div>
              </div>
            </div>
          </div>
        </v-form>

        <v-dialog v-model="addDialog" persistent max-width="600px">
          <v-form v-model="addDialogformvalid" @submit.prevent="addItem">
            <v-card>
              <v-card-title>
                <span class="headline">Add New Item</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="addDialogform.name"
                        label="Name"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="addDialogform.skuid"
                        label="SKU #"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addDialogClose">
                  Close
                </v-btn>
                <v-btn
                  type="submit"
                  :disabled="!addDialogformvalid"
                  color="blue darken-1"
                  text
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import AssortmentAddProduct from '@/graphql/mutations/AssortmentAddProduct.gql'
import GetCompanyTags from '@/graphql/queries/GetCompanyTags.gql'
import CreateFullProduct from '@/graphql/mutations/CreateFullProduct.gql'
import UploadFile from '@/graphql/mutations/UploadFile.gql'
import GetProductsBySupplier from '@/graphql/queries/GetProductsBySupplier.gql'

export default {
  components: {},
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  mixins: [validationMixin],

  data: () => ({
    chipsearch: '',
    accountTab: 'General',
    selected: 2,
    panel: [0, 1],
    panel2: [0, 1],
    isLoading: false,
    assortments: [],
    youtube: '',
    case_pack: '',
    vimeo: '',
    weight: '',
    length_in: '',
    width_in: '',
    height_in: '',
    shipping_info: '',
    marketing_text: '',
    ingredients: '',
    brand: '',
    second_name: '',
    main_image: null,
    additional_image: null,
    additional_images: [],
    main_image_url: '',
    main_image_name: '',
    main_image_id: '',
    limit: 10,
    currentPage: 1,
    totalCount: 0,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    name: '',
    part_id: '',
    created_at: '',
    status: 'new',
    product_options: [
      { value: 'new', text: 'New' },
      { value: 'pending', text: 'Pending' },
      { value: 'discontinued', text: 'Discontinued' },
      { value: 'active', text: 'Active' },
    ],
    type: '',
    type_options: [
      { value: '', text: 'Choose Type' },
      { value: 'simple', text: 'Simple' },
      { value: 'has_variants', text: 'Has Variants' },
      { value: 'virtual', text: 'Virtual' },
    ],
    attribute_set: 'clothing',
    attribute_set_options: [
      { value: '', text: 'Choose Set of Attributes' },
      { value: 'standard', text: 'Standard' },
      { value: 'clothing', text: 'Clothing' },
      { value: 'clothing 2', text: 'Clothing 2' },
      { value: 'food', text: 'Food' },
      { value: 'misc', text: 'Misc' },
    ],
    chips: [],
    featured: false,
    short_description: '',
    long_description: '',
    fields: [
      {
        key: 'index',
        label: '#',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'sku',
        label: 'Sku #',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    items: [],
    addDialog: false,
    addDialogformvalid: false,
    addDialogform: {
      name: '',
      skuid: '',
    },
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',

    editor: ClassicEditor,

    editorConfig: {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
    },
    options: [
      { value: null, text: 'New' },
      { value: 'draft', text: 'Draft' },
      { value: 'awaiting', text: 'Awaiting Approval' },
      { value: 'active', text: 'Active ' },
      { value: 'disco', text: 'Discontinued' },
      { value: 'eol', text: 'End of Life' },
    ],
    logic: ['Active', 'Pending', 'Discontinued', 'NOT Discontinued'],

    dialog: false,
    dialog2: false,
    tagHeader: [{ header: 'Select an option or create one' }],
  }),
  computed: {
    ...mapGetters(['currentUser']),
    tagNames: function() {
      const allTags = this.tags ? this.tags.map((t) => t.name) : []
      return [].concat(this.tagHeader, allTags)
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'getProductWhere',
      'getProductById',
      'deleteFile',
      'removeProduct',
      'submitItem',
      'getItemTotalCount',
      'removeItem',
      'getProductByAssortment',
      'getProductTotalCount',
      'getAssortmentsBySupplier',
      'getAssortmentProductTotalCount',
    ]),
    async addimageUpload() {
      if (this.additional_image != null) {
        await this.updateProductAdditionalImg({
          id: this.$route.params.id,
          additional_image: this.additional_image.imageFile,
        })
        await this.refreshAdditionalImg()
      }
    },
    async refreshAdditionalImg() {
      const res = await this.getProductById({
        id: this.$route.params.id,
      })
      if (res.data.additional_image != null) {
        this.additional_image = null
        this.additional_images = res.data.additional_image
      }
    },
    imageChange(e) {
      let target = e.target || e.srcElement
      let files = target.files
      let fr = new FileReader()
      fr.onload = function() {
        document.getElementById('image_cover').src = fr.result
      }
      fr.readAsDataURL(files[0])
    },
    imageDelete() {
      this.main_image = ''
      this.main_image_name = ''
      this.main_image_url = ''
    },
    async submitAssortmentproduct(assortmentId, productId) {
      await this.$apollo.mutate({
        mutation: AssortmentAddProduct,
        variables: { assortmentId: assortmentId, productId },
      })
    },
    addDialogClose() {
      this.addDialog = false
    },
    async submit(e) {
      e.preventDefault()

      this.isLoading = true
      const partid_products = await this.$apollo.query({
        query: GetProductsBySupplier,
        variables: {
          supplierId: this.$store.state.auth.user.company_relation,
          partId: this.part_id,
        },
      })
      // let partid_products = await this.getProductWhere({
      //   where:
      //     '?_where[0][part_id]=' +
      //     this.part_id +
      //     '&_where[1][supplier]=' +
      //     this.$store.state.auth.user.company_relation,
      // })
      if (partid_products.data.products.length > 0) {
        this.isLoading = false
        Swal.fire({
          title: '',
          text: 'A product with same SKU/Part # already exists in the company.',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
        return
      }

      const newProduct = {
        name: this.name,
        part_id: this.part_id,
        status: this.status,
        featured: this.featured,
        short_description: this.short_description,
        long_description: this.long_description,
        youtube: this.youtube,
        vimeo: this.vimeo,
        type: this.type,
        attribute_set: this.attribute_set,
        supplier: this.$store.state.auth.user.company_relation,
        weight: this.weight ? parseFloat(this.weight) : undefined,
        length_in: this.length_in ? parseFloat(this.length_in) : undefined,
        width_in: this.width_in ? parseFloat(this.width_in) : undefined,
        height_in: this.height_in ? parseFloat(this.height_in) : undefined,
        brand: this.brand,
        marketing_text: this.marketing_text,
        ingredients: this.ingredients,
        shipping_info: this.shipping_info,
        second_name: this.second_name,
        case_pack: this.case_pack ? parseInt(this.case_pack) : undefined,
      }

      const res = await this.$apollo.mutate({
        mutation: CreateFullProduct,
        variables: {
          newProduct,
          searchTags: this.chips,
        },
      })

      // refreshes tags incase a new one was created
      this.$apollo.queries.tags.refetch()

      const hasImageToUpload = this.main_image instanceof File

      if (hasImageToUpload) {
        await this.$apollo.mutate({
          mutation: UploadFile,
          variables: {
            contentType: 'product',
            contentId: res.data.createFullProduct.product.id,
            field: 'main_image',
            file: this.main_image,
          },
        })
      }

      await this.submitItem({
        Name: this.name,
        sku_id: this.part_id,
        product: res.data.createFullProduct.product.id,
      })

      this.isLoading = false

      Swal.fire({
        title: '',
        text: 'The product has been successfully created!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })

      this.$router.push('/pim/list')
    },
  },
}
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
.popup-visible {
  position: absolute;
  z-index: 10;
  visibility: visible;
}
</style>
